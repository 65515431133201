import { useState } from "react"
import { IAnswers } from "../App"

export default function Page9({ answers, updateAnswers }:{answers:IAnswers, updateAnswers: React.Dispatch<React.SetStateAction<IAnswers>>}){
    const [terms, updateTerms] = useState(answers.q43.map((item) => { return { id:newGuid(), value: item } }))
    const [inputText, updateInputText] = useState("")


    function handleKeypress(e:React.KeyboardEvent<HTMLInputElement>){
        if (e.key === "Enter" && e.currentTarget.value !== ""){
            let newTerms = terms
            newTerms.push({ id: newGuid(), value: e.currentTarget.value})
            updateTerms(newTerms)
            updateAnswers({ ...answers, q43: newTerms.map((term) => { return term.value }) })
            updateInputText("")
        }
    }

    function newGuid(){
       return 'xxxxxxxx'.replace(/[xy]/g, function(c) {
            var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
          });
    }

    return (
        <>
            <h3>43) Write down as many uses of a brick that you can think of<br/><small>Take about 1 minute or less if you run out of ideas<br/>(press enter after each use you think of)</small></h3>
            <input type="text" onKeyDown={ handleKeypress } value={inputText} onChange={ (e) => { updateInputText(e.currentTarget.value) } }/>
            <h3>Your selection:</h3>
            <ol className="terms">
                { 
                    terms.length === 0 ? <i>You haven't added any items</i> : terms.map((term) => <li key={ term.id }>{ term.value }</li>)
                }
            </ol>
        </>
    )
}
import { IAnswers } from "../App"
import Slider from "../components/Slider"

export default function Page2({ answers, updateAnswers }:{answers:IAnswers, updateAnswers: React.Dispatch<React.SetStateAction<IAnswers>>}){
    
    return (
        <>
            <span>On a typical day at work, do you consider yourself to be:</span>
            <Slider question="7) A person who is..." selectedValue={answers.q7} onChange={ (value) => { updateAnswers({ ...answers, q7: value }) }} lowDescription="Detail oriented" highDescription="Big-picture oriented" />
            <Slider question="8) a person who makes decisions based on..." selectedValue={answers.q8} onChange={ (value) => { updateAnswers({ ...answers, q8: value }) }} lowDescription="Logical reasoning" highDescription="Personal values" />
            <Slider marginBottom={ 100 } question="9) a person who..." selectedValue={answers.q9} onChange={ (value) => { updateAnswers({ ...answers, q9: value }) }} lowDescription="Takes things at face value" highDescription="Questions things until clarity is achieved" />
            <Slider marginBottom={ 120 } question="10) a person who..." selectedValue={answers.q10} onChange={ (value) => { updateAnswers({ ...answers, q10: value }) }} lowDescription="Usually agrees with the majority" highDescription="Usually has views different from the mainstream" />
            <Slider question="11) a person who is aligned with a political ideology" selectedValue={answers.q11} onChange={ (value) => { updateAnswers({ ...answers, q11: value }) }} lowDescription="A lot like me" highDescription="Not like me at all" />
        </>
    )
}
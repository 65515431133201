export default function SelectMulti({question, onChange, options, selectedValues}:{question:string, onChange:(values:Array<string>) => void, options:Array<{value:string,text:string}>, selectedValues:Array<string>}){
    function handleChange(e:React.ChangeEvent<HTMLInputElement>){
        if (selectedValues.indexOf(e.target.value) === -1){
            selectedValues.push(e.target.value)
            onChange(selectedValues)
        } else{
            onChange(selectedValues.filter((val) => {
                return val !== e.target.value
            }))
        }
    }
    
    return (
        <>
            <h3>{ question }<br/><small>Select all that apply</small></h3>
            <ul>
                {
                    options.map((option) => 
                    <li key={ option.value }>
                        <label className="checkbox">
                            <input type="checkbox" onChange={ handleChange } value={ option.value } checked={ selectedValues.indexOf(option.value) > -1 }/> { option.text }
                        </label>
                    </li>
                    )
                }
            </ul>
        </>
    )
}
import { IAnswers } from "../App"
import Slider from "../components/Slider"

export default function Page4({ answers, updateAnswers }:{answers:IAnswers, updateAnswers: React.Dispatch<React.SetStateAction<IAnswers>>}){
    
    return (
        <>
            <span>On a typical day at work, do you consider yourself to be:</span>
            <Slider question="17) A person who is a..." selectedValue={answers.q17} onChange={ (value) => { updateAnswers({ ...answers, q17: value }) }} lowDescription="Trend maker" highDescription="Trend breaker" />
            <Slider marginBottom={ 100 } question="18) A person who..." selectedValue={answers.q18} onChange={ (value) => { updateAnswers({ ...answers, q18: value }) }} lowDescription="Looks for external validation of what is right" highDescription="Trusts their instincts to know what is right" />
            <Slider question="19) a person who runs towards information that doesn’t fit their view of the world?" selectedValue={answers.q19} onChange={ (value) => { updateAnswers({ ...answers, q19: value }) }} lowDescription="A lot like me" highDescription="Not like me at all" />
            <Slider marginBottom={ 120 } question="20) a person who obtains their news..." selectedValue={answers.q20} onChange={ (value) => { updateAnswers({ ...answers, q20: value }) }} lowDescription="From sources that I trust" highDescription="From multiple often conflicting sources" />
            <Slider marginBottom={ 120 } question="21) a person who believes white lies..." selectedValue={answers.q21} onChange={ (value) => { updateAnswers({ ...answers, q21: value }) }} lowDescription="Are the foundation on which society operates" highDescription="Cause more harm than good" />
        </>
    )
}
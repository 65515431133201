import { faChevronDown, faBars } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";

export default function OtherSitesDropdown(){
    const [open, setOpen] = useState(false)

    return (
        <div className={"other-sites" + (open ? " open" : "" )}>
            <button className="text" onClick={() => { setOpen(!open) }}>TestMyThinking <FontAwesomeIcon icon={ faChevronDown } /></button>
            <button className="icon" onClick={() => { setOpen(!open) }}><FontAwesomeIcon icon={ faBars } /></button>
            <ul>
                <li>
                    <a href="https://www.patentinspiration.com">
                        <h2>PatentInspiration</h2>
                        <span>Search and analyze patents</span>
                    </a>
                </li>
                <li>
                    <a href="https://www.moreinspiration.com">
                        <h2>MoreInspiration</h2>
                        <span>Browse the latest innovations</span>
                    </a>
                </li>
                <li>
                    <a href="https://www.testmycreativity.com">
                        <h2>TestMyCreativity</h2>
                        <span>Test your level of creativity</span>
                    </a>
                </li>
                <li>
                    <a href="https://www.productioninspiration.com">
                        <h2>ProductionInspiration</h2>
                        <span>Find new production ways</span>
                    </a>
                </li>
                <li>
                    <a href="https://www.innovationlogic.com">
                        <h2>InnovationLogic</h2>
                        <span>How-To-Innovate Workshop Training</span>
                    </a>
                </li>
                <li>
                    <a href="https://www.testmythinking.com">
                        <h2>TestMyThinking</h2>
                        <span>Autonomous Thinking Self-Assessment</span>
                    </a>
                </li>
            </ul>
        </div>
    )
}
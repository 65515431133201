import { IAnswers } from "../App"
import Slider from "../components/Slider"

export default function Page6({ answers, updateAnswers }:{answers:IAnswers, updateAnswers: React.Dispatch<React.SetStateAction<IAnswers>>}){
    
    return (
        <>
            <span>On a typical day at work, do you consider yourself to be:</span>
            <Slider question="27) a person who is pragmatic?" selectedValue={answers.q27} onChange={ (value) => { updateAnswers({ ...answers, q27: value }) }} lowDescription="A lot like me" highDescription="Not like me at all" />
            <Slider question="28) a person who believes we should all be responsible for our own health & happiness?" selectedValue={answers.q28} onChange={ (value) => { updateAnswers({ ...answers, q28: value }) }} lowDescription="A lot like me" highDescription="Not like me at all" />
            <Slider marginBottom={ 140 } question="29) a person who believes the best way to make ourselves more resilient is to..." selectedValue={answers.q29} onChange={ (value) => { updateAnswers({ ...answers, q29: value }) }} lowDescription="Keep improving what we're good at" highDescription="Spend as much time outside our comfort zone as possible" />
            <Slider question="30) a person who believes the best experts to take advice from are..." selectedValue={answers.q30} onChange={ (value) => { updateAnswers({ ...answers, q30: value }) }} lowDescription="Specialists" highDescription="Generalists" />
            <Slider question="31) a person who understands it is possible to have too much knowledge?" selectedValue={answers.q31} onChange={ (value) => { updateAnswers({ ...answers, q31: value }) }} lowDescription="A lot like me" highDescription="Not like me at all" />
        </>
    )
}
import { IAnswers } from "../App"
import Slider from "../components/Slider"

export default function Page5({ answers, updateAnswers }:{answers:IAnswers, updateAnswers: React.Dispatch<React.SetStateAction<IAnswers>>}){
    
    return (
        <>
            <span>On a typical day at work, do you consider yourself to be:</span>
            <Slider question="22) a person who believes personal autonomy is more important than tribal belonging" selectedValue={answers.q22} onChange={ (value) => { updateAnswers({ ...answers, q22: value }) }} lowDescription="A lot like me" highDescription="Not like me at all" />
            <Slider question="23) a person who is comfortable being confused about a subject, even if it for weeks or months?" selectedValue={answers.q23} onChange={ (value) => { updateAnswers({ ...answers, q23: value }) }} lowDescription="A lot like me" highDescription="Not like me at all" />
            <Slider question="24) a person who believes truth is most likely to be heard from those with ‘skin in the game’" selectedValue={answers.q24} onChange={ (value) => { updateAnswers({ ...answers, q24: value }) }} lowDescription="A lot like me" highDescription="Not like me at all" />
            <Slider question="25) a person who believes in fairness, equality and equity?" selectedValue={answers.q25} onChange={ (value) => { updateAnswers({ ...answers, q25: value }) }} lowDescription="A lot like me" highDescription="Not like me at all" />
            <Slider question="26) a person who believes telling ‘truth to power’ is important?" selectedValue={answers.q26} onChange={ (value) => { updateAnswers({ ...answers, q26: value }) }} lowDescription="A lot like me" highDescription="Not like me at all" />
        </>
    )
}
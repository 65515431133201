import { useState, useEffect } from 'react';
import CustomDropdown from './components/Dropdown';
import OtherSitesDropdown from './OtherSitesDropdown';
import SelectSingle from './components/SelectSingle';
import SelectMulti from './components/SelectMulti';
import Page1 from './pages/page1';
import ProgressBar from './ProgressBar';
import Page2 from './pages/page2';
import Page3 from './pages/page3';
import Page4 from './pages/page4';
import Page5 from './pages/page5';
import Page6 from './pages/page6';
import Page7 from './pages/page7';
import Page8 from './pages/page8';
import Page9 from './pages/page9';
import Result from './pages/result';

export interface IAnswers{
  q1: number,
  q2: string,
  q3: Array<string>,
  q4: number,
  q5: number,
  q6: number,
  q7: number,
  q8: number,
  q9: number,
  q10: number,
  q11: number,
  q12: number,
  q13: number,
  q14: number,
  q15: number,
  q16: number,
  q17: number,
  q18: number,
  q19: number,
  q20: number,
  q21: number,
  q22: number,
  q23: number,
  q24: number,
  q25: number,
  q26: number,
  q27: number,
  q28: number,
  q29: number,
  q30: number,
  q31: number,
  q32: Array<string>,
  q33: Array<string>,
  q34: Array<string>,
  q35: Array<string>,
  q36: Array<string>,
  q37: number,
  q38: number,
  q39: number,
  q40: number,
  q41A: number,
  q41B: number,
  q41C: number,
  q41D: number,
  q42A: number,
  q42B: number,
  q42C: number,
  q43: Array<string>
}

function App() {
  const [started, setStarted] = useState(false)

  const initialState = {
    q1: 0,
    q2: 'NL',
    q3: [] as Array<string>,
    q4: 0,
    q5: 0,
    q6: 0,
    q7: 5,
    q8: 5,
    q9: 5,
    q10: 5,
    q11: 5,
    q12: 5,
    q13: 5,
    q14: 5,
    q15: 5,
    q16: 5,
    q17: 5,
    q18: 5,
    q19: 5,
    q20: 5,
    q21: 5,
    q22: 5,
    q23: 5,
    q24: 5,
    q25: 5,
    q26: 5,
    q27: 5,
    q28: 5,
    q29: 5,
    q30: 5,
    q31: 5,
    q32: [] as Array<string>,
    q33: [] as Array<string>,
    q34: [] as Array<string>,
    q35: [] as Array<string>,
    q36: [] as Array<string>,
    q37: 0,
    q38: 0,
    q39: 0,
    q40: 0,
    q41A: 0,
    q41B: 0,
    q41C: 0,
    q41D: 0,
    q42A: 0,
    q42B: 0,
    q42C: 0,
    q43: [] as Array<string>
  }

  const pageCount = 10;

  const [answers, updateAnswers] = useState(initialState)

  const [currentPage, updatePage] = useState(1)

  function renderPage(pageNumber:number){
    switch (pageNumber) {
      case 1:
        return <Page1 answers={ answers } updateAnswers={ updateAnswers } />
      case 2:
        return <Page2 answers={ answers } updateAnswers={ updateAnswers } />
      case 3:
        return <Page3 answers={ answers } updateAnswers={ updateAnswers } />
      case 4:
        return <Page4 answers={ answers } updateAnswers={ updateAnswers } />
      case 5:
        return <Page5 answers={ answers } updateAnswers={ updateAnswers } />
      case 6:
        return <Page6 answers={ answers } updateAnswers={ updateAnswers } />
      case 7:
        return <Page7 answers={ answers } updateAnswers={ updateAnswers } />
      case 8:
        return <Page8 answers={ answers } updateAnswers={ updateAnswers } />
      case 9:
        return <Page9 answers={ answers } updateAnswers={ updateAnswers } />
      case 10:
        return <Result answers={ answers } updateAnswers={ updateAnswers } />
    }
  }

  function handlePrev(){
    if (currentPage <= 1)
      return;
    
    updatePage(currentPage-1)
  }

  function handleNext(){
    if (currentPage >= pageCount)
      return;
  
    updatePage(currentPage+1)
  }

  return (
    <>
      <header>
        <div className='container'>
          <a href="https://www.aulive.com"><h1>ΔULIVE</h1></a>
          <OtherSitesDropdown />
        </div>
      </header>
      <div className="container">
        { !started ?
            <article className='welcome'>
              <img className='logo' src="logo.svg" alt="Logo"/>
              <h1>Welcome to the Autonomous Thinking Self-Assessment</h1>
              <h2>A unique personal creativity profiling tool</h2>
              <h2>Answer these questions and receive a FREE assessment of your ability to think autonomously and effectively!</h2>
              <button className='primary' onClick={ () => { setStarted(true) } }>Start the test</button>
            </article>
            :
            <article className='questionnaire'>
              <img className='logo' src="logo.svg" alt="Logo"/>
              <ProgressBar percentage={ (currentPage / pageCount) * 100 } />
              { renderPage(currentPage) }
              <div className='nav-buttons'>
                { currentPage < pageCount ? <button className='primary next' onClick={ handleNext }>Next</button> : "" }
                { currentPage > 1 ? <button className='primary prev' onClick={ handlePrev }>Prev</button> : "" }
              </div>
            </article>
        }
      </div>
    </>
  );
}

export default App;

export default function SelectSingle({question, onChange, options, selectedValue}:{question:string, onChange: (value:string) => void, options:Array<{ value:string,text:string }>, selectedValue:string}){
    const group = 'xxxxxxxx'.replace(/[xy]/g, function(c) {
        var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
      });

    function handleChange(e:React.ChangeEvent<HTMLInputElement>){
        onChange(e.currentTarget.value)
    }
    
    return (
        <>
            <h3>{ question }</h3>
            <ul>
                {
                    options.map((option) => 
                    <li key={ option.value }>
                        <label className="radio">
                            <input type="radio" onChange={ handleChange } value={ option.value } name={group} checked={ option.value === selectedValue }/> { option.text }
                        </label>
                    </li>
                    )
                }
            </ul>
        </>
    )
}
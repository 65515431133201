export default function Slider({question, marginBottom, onChange, selectedValue, lowDescription, highDescription}:{question:string, marginBottom?:number, onChange:(value:number) => void, selectedValue:number, lowDescription:string, highDescription:string}){
    function handleClick(e:React.MouseEvent<HTMLDivElement>){
        //get position
        let bbox = e.currentTarget.getBoundingClientRect()
        onChange(Math.round(((((e.pageX - bbox.left) / bbox.width) * 0.9) * 10) + 1))
    }

    if (!marginBottom)
        marginBottom = 70;
    
    return (
        <>
            <h3>{ question }</h3>
            <div className="slider" style={{ marginBottom:marginBottom + "px"}} onClick={ handleClick }>
                <div className="marker" style={{ left: (selectedValue - 1) * 11.1111 + "%" }}></div>
                <ul>
                    <li>
                        <div className="line"></div>
                        <div className="text">{ lowDescription }</div>
                    </li>
                    <li>
                        <div className="line"></div>
                    </li>
                    <li>
                        <div className="line"></div>
                    </li>
                    <li>
                        <div className="line"></div>
                    </li>
                    <li>
                        <div className="line"></div>
                    </li>
                    <li>
                        <div className="line"></div>
                    </li>
                    <li>
                        <div className="line"></div>
                    </li>
                    <li>
                        <div className="line"></div>
                    </li>
                    <li>
                        <div className="line"></div>
                    </li>
                    <li>
                        <div className="line"></div>
                        <div className="text">{ highDescription }</div>
                    </li>
                </ul>
            </div>
        </>
    )
}
import { IAnswers } from "../App"
import SelectMulti from "../components/SelectMulti"
import SelectSingle from "../components/SelectSingle"

export default function Page8({ answers, updateAnswers }:{answers:IAnswers, updateAnswers: React.Dispatch<React.SetStateAction<IAnswers>>}){
    
    const q37 = [
        { value:"0", text:"Sitting flat on the sand" },
        { value:"1", text:"Sitting at an angle, partially buried in the sand" },
        { value:"2", text:"Buried in the sand so that just one pyramid-like corner is above the surface" },
        { value:"3", text:"Mostly buried in the sand" },
        { value:"4", text:"Floating in the air above the sand" },
        { value:"5", text:"Sitting flat on the top of a hill (or mountain) of sand" },
        { value:"6", text:"Sitting on the sand, balancing on one of its edges" },
        { value:"7", text:"Sitting on the sand, balancing on one of its corners" },
        { value:"8", text:"A cubic hole in the sand (possibly filled with water or another fluid)" },
    ]

    const q38 = [
        { value:"0", text:"Tiny (<1mm)"},
        { value:"1", text:"The size of a dice"},
        { value:"2", text:"The size of a football"},
        { value:"3", text:"As tall as a human"},
        { value:"4", text:"As tall as a house"},
        { value:"5", text:"As tall as a skyscraper"},
        { value:"6", text:"Mountain-sized"},
    ]

    const q39 = [
        { value:"0", text:"I am inside the cube"},
        { value:"1", text:"I am pressed against the cube"},
        { value:"2", text:"I am within touching distance"},
        { value:"3", text:"The cube is a stone's throw away"},
        { value:"4", text:"The cube is within walking distance"},
        { value:"5", text:"Seeing the cube requires binoculars"},
        { value:"6", text:"The cube is over the other side of a mountain, not visible to me"},
    ]

    const q40 = [
        { value:"0", text:"A hard material (rock, metal, concrete, ice, diamond, wood etc)"},
        { value:"1", text:"A soft material (rubber, cheese, cloth, canvas, paper etc)"},
        { value:"2", text:"A liquid or gas (honey, air, water, milk, oxygen etc)"},
        { value:"3", text:"A 'field' (electrical, laser, magnetic etc)"},
    ]

    return (
        <>
            <SelectSingle question="37) Close your eyes. Imagine yourself in the middle of a desert. Ahead of you is a CUBE. The cube is:" onChange={(value) => updateAnswers({ ...answers, q37: parseInt(value) })} selectedValue={ answers.q37.toString() } options={ q37 } />
            <SelectSingle question="38) How big is the CUBE?" onChange={(value) => updateAnswers({ ...answers, q38: parseInt(value) })} selectedValue={ answers.q38.toString() } options={ q38 } />
            <SelectSingle question="39) How far away is the CUBE from where you are standing?" onChange={(value) => updateAnswers({ ...answers, q39: parseInt(value) })} selectedValue={ answers.q39.toString() } options={ q39 } />
            <SelectSingle question="40) What is the CUBE made from?" onChange={(value) => updateAnswers({ ...answers, q40: parseInt(value) })} selectedValue={ answers.q40.toString() } options={ q40 } />
            <SelectSingle question="41) What does the CUBE look like?" onChange={ (value) => updateAnswers({ ...answers, q41A: parseInt(value) }) } selectedValue={ answers.q41A.toString() } options={[{ value:"0", text:"It is solid"}, { value:"1", text:"It is hollow"}]} />
            <SelectSingle question="" onChange={ (value) => updateAnswers({ ...answers, q41B: parseInt(value) }) } selectedValue={ answers.q41B.toString() } options={[{ value:"0", text:"It is shiny"}, { value:"1", text:"It is matte"}]} />
            <SelectSingle question="" onChange={ (value) => updateAnswers({ ...answers, q41C: parseInt(value) }) } selectedValue={ answers.q41C.toString() } options={[{ value:"0", text:"It is transparent"}, { value:"1", text:"It is opaque"}]} />
            <SelectSingle question="" onChange={ (value) => updateAnswers({ ...answers, q41D: parseInt(value) }) } selectedValue={ answers.q41D.toString() } options={[{ value:"0", text:"It is smooth"}, { value:"1", text:"It is textured"}]} />
            <SelectSingle question="42) Now imagine there is also a LADDER. What is the ladder made of? Where is it in relation to the cube? How many rungs does it have?" onChange={ (value) => updateAnswers({ ...answers, q42A: parseInt(value) }) } selectedValue={ answers.q42A.toString() } options={[{ value:"0", text:"It is wooden"}, { value:"1", text:"It is metal"}, { value:"2", text:"It is made from rope"}]} />
            <SelectSingle question="" onChange={ (value) => updateAnswers({ ...answers, q42B: parseInt(value) }) } selectedValue={ answers.q42B.toString() } options={[{ value:"0", text:"It is leaning against the CUBE"}, { value:"1", text:"It is standing independently, adjacent to the CUBE"}, { value:"2", text:"It is a long way away in the distance"}, { value:"3", text:"It is connecting a floating CUBE to the ground"}]} />
            <SelectSingle question="" onChange={ (value) => updateAnswers({ ...answers, q42C: parseInt(value) }) } selectedValue={ answers.q42C.toString() } options={[{ value:"0", text:"Many rungs (too many to count)"}, { value:"1", text:"Several"}, { value:"2", text:"Few"}]} />
        </>
    )
}
import { IAnswers } from "../App"
import SelectMulti from "../components/SelectMulti"

export default function Page7({ answers, updateAnswers }:{answers:IAnswers, updateAnswers: React.Dispatch<React.SetStateAction<IAnswers>>}){
    
    return (
        <>
            <SelectMulti question="32) Which of these words do you think connect to both SUBMARINE and CAMERA?" onChange={ (values:Array<string>) => { updateAnswers({ ...answers, q32: values }) }} selectedValues={ answers.q32 } options={ [{value:"0", text:"Watertight"},{value:"1", text:"Film"},{value:"2", text:"Lens"},{value:"3", text:"Battery"},{value:"4", text:"Door"},{value:"5", text:"Egg"},{value:"6", text:"Octopus"},] } />
            <SelectMulti question="33) Which of these words do you think connect to both RADIO and KNIFE?" onChange={ (values:Array<string>) => { updateAnswers({ ...answers, q33: values }) }} selectedValues={ answers.q33 } options={ [{value:"0", text:"Sharp"},{value:"1", text:"Twist"},{value:"2", text:"Steel"},{value:"3", text:"Fish"},{value:"4", text:"Switch"},{value:"5", text:"Sea"},{value:"6", text:"Telescope"},] } />
            <SelectMulti question="34) Which of these words do you think connect to both CUSHION and TABLE?" onChange={ (values:Array<string>) => { updateAnswers({ ...answers, q34: values }) }} selectedValues={ answers.q34 } options={ [{value:"0", text:"Foam"},{value:"1", text:"Clock"},{value:"2", text:"Load"},{value:"3", text:"Towel"},{value:"4", text:"Apple"},{value:"5", text:"Lift"},{value:"6", text:"Trumpet"},] } />
            <SelectMulti question="35) Which of these words do you think connect to both CAR and TREE?" onChange={ (values:Array<string>) => { updateAnswers({ ...answers, q35: values }) }} selectedValues={ answers.q35 } options={ [{value:"0", text:"Caterpillar"},{value:"1", text:"Leaf"},{value:"2", text:"Rubber"},{value:"3", text:"Temple"},{value:"4", text:"Dynamic"},{value:"5", text:"Post"},{value:"6", text:"Triangle"},] } />
            <SelectMulti question="36) Which of these words do you think connect to both WET and ARMADILLO?" onChange={ (values:Array<string>) => { updateAnswers({ ...answers, q36: values }) }} selectedValues={ answers.q36 } options={ [{value:"0", text:"Texas"},{value:"1", text:"Castle"},{value:"2", text:"Plug"},{value:"3", text:"Rattle"},{value:"4", text:"Diary"},{value:"5", text:"Laptop"},{value:"6", text:"Ear"},] } />
        </>
    )
}
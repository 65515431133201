import { useEffect } from "react"
import { IAnswers } from "../App"
import * as d3 from "d3"

export default function Result({ answers, updateAnswers }:{answers:IAnswers, updateAnswers: React.Dispatch<React.SetStateAction<IAnswers>>}){
    const result = getResult()

    useEffect(() => {
        let svg = d3.select("#graph")
            .append("svg")
            .attr("width", 500)
            .attr("height", 500)

        //add lines
        let linesGroup = svg.append("g")

        const spacing = [0, 100, 200, 300, 400]
        //horizontal lines
        linesGroup.append('g')
            .selectAll("rect")
            .data(spacing)
            .enter()
            .append("rect")
            .attr("x", 80)
            .attr("y", (data) => { return 20 + data })
            .attr('width', 402)
            .attr('height', (d, i) => {
                return i === 0 || i === 4 ? 2 : 1
            })
            .style("fill", "#dcdcdc")

        //vertical lines
        linesGroup.append('g')
            .selectAll("rect")
            .data(spacing)
            .enter()
            .append("rect")
            .attr("x", (data) => { return 80 + data })
            .attr("y", 20)
            .attr('width', (d, i) => {
                return i === 0 || i === 4 ? 2 : 1
            })
            .attr('height', 400)
            .style("fill", "#dcdcdc")

        //pointer
        svg.append("circle")
            .attr("cx", 80 + (400 * (result.x / 10)))
            .attr("cy", 420 - (400 * (result.y / 10)))
            .attr("r", 15)
            .style("fill", "#7e57c2")

        //axis
        //top left
        svg.append("text")
            .attr("x", 60)
            .attr("y", 20)
            .text("10")
            .style("font-size", "16px")
            .style("fill", "#dcdcdc")

        //bottom right
        svg.append("text")
            .attr("x", 483)
            .attr("y", 433)
            .text("10")
            .style("font-size", "16px")
            .style("fill", "#dcdcdc")

        //bottom left
        svg.append("text")
        .attr("x", 68)
        .attr("y", 433)
        .text("0")
        .style("font-size", "16px")
        .style("fill", "#dcdcdc")

        //Y Axis
        let yAxis = svg.append("g")
        yAxis.append("text")
            .text("Holistic")
            .attr("transform", "translate(70,100)rotate(-90)")
            .style("font-size", "16px")
            .style("font-weight", "bold")

        yAxis.append("text")
            .text("Objective")
            .attr("transform", "translate(70,205)rotate(-90)")
            .style("font-size", "16px")
            .style("font-weight", "bold")

        yAxis.append("text")
            .text("Doctrinaire")
            .attr("transform", "translate(70,310)rotate(-90)")
            .style("font-size", "16px")
            .style("font-weight", "bold")

        yAxis.append("text")
            .text("Impulsive")
            .attr("transform", "translate(70,405)rotate(-90)")
            .style("font-size", "16px")
            .style("font-weight", "bold")

        yAxis.append("text")
            .text("Desire / Ability To Find Truth")
            .attr("transform", "translate(40,315)rotate(-90)")
            .style("font-size", "16px")
            .style("fill", "#9a9a9a")

        //X Axis
        let xAxis = svg.append("g")
        xAxis.append("text")
            .text("Innocent")
            .attr("transform", "translate(100,440)")
            .style("font-size", "16px")
            .style("font-weight", "bold")

        xAxis.append("text")
            .text("Orphan")
            .attr("transform", "translate(205,440)")
            .style("font-size", "16px")
            .style("font-weight", "bold")

        xAxis.append("text")
            .text("Pilgrim")
            .attr("transform", "translate(305,440)")
            .style("font-size", "16px")
            .style("font-weight", "bold")
            
        xAxis.append("text")
            .text("Magician")
            .attr("transform", "translate(400,440)")
            .style("font-size", "16px")
            .style("font-weight", "bold")
            
        xAxis.append("text")
            .text("Independent / Autonomous Thinker")
            .attr("transform", "translate(150,470)")
            .style("font-size", "16px")
            .style("fill", "#9a9a9a")


        return () => {
            d3.selectAll("svg").remove()
        }
    }, [])

    function getResult(){
        let scores = []

        scores[7] = answers.q7 < 6 ? answers.q7 : (10 - answers.q7)
        scores[8] = answers.q8
        scores[9] = answers.q9
        scores[10] = answers.q10
        scores[11] = answers.q11
        scores[12] = answers.q12
        scores[13] = answers.q13
        scores[14] = answers.q14
        scores[15] = answers.q15 < 6 ? answers.q15 : (10 - answers.q15)
        scores[16] = answers.q16
        scores[17] = answers.q17
        scores[18] = answers.q18 < 6 ? answers.q18 : (10 - answers.q18)
        scores[19] = answers.q19
        scores[20] = answers.q20
        scores[21] = answers.q21 < 6 ? answers.q21 : (10 - answers.q21)
        scores[22] = answers.q22
        scores[23] = answers.q23
        scores[24] = answers.q24 < 6 ? answers.q24 : (10 - answers.q24)
        scores[25] = answers.q25
        scores[26] = answers.q26
        scores[27] = answers.q27 < 6 ? answers.q27 : (10 - answers.q27)
        scores[28] = answers.q28
        scores[29] = answers.q29
        scores[30] = answers.q30 < 6 ? answers.q30 : (10 - answers.q30)
        scores[31] = answers.q31
        scores[32] = answers.q32.length
        scores[33] = answers.q33.length
        scores[34] = answers.q34.length
        scores[35] = answers.q35.length
        scores[36] = answers.q36.length
        
        switch (answers.q37) {
            case 0:
                scores[37] = 6.5
                break;
            case 1:
                scores[37] = 8
                break;
            case 2:
            case 6:
                scores[37] = 6
                break;
            case 3:
                scores[37] = 4
                break;
            case 4:
                scores[37] = 9
                break;
            case 5:
                scores[37] = 3.5
                break;
            case 7:
                scores[37] = 3
                break;
            case 8:
                scores[37] = 2
                break;
        }

        switch (answers.q38) {
            case 0:
                scores[38] = 4
                break;
            case 1:
                scores[38] = 2
                break;
            case 2:
                scores[38] = 3
                break;
            case 3:
                scores[38] = 6
                break;
            case 4:
                scores[38] = 8
                break;
            case 5:
                scores[38] = 9
                break;
            case 6:
                scores[38] = 6
                break;
        }

        switch (answers.q39) {
            case 0:
                scores[39] = 2
                break;
            case 1:
                scores[39] = 4
                break;
            case 2:
                scores[39] = 5
                break;
            case 3:
                scores[39] = 6
                break;
            case 4:
                scores[39] = 7
                break;
            case 5:
                scores[39] = 8
                break;
            case 6:
                scores[39] = 10
                break;
        }

        switch (answers.q40) {
            case 0:
                scores[40] = 6
                break;
            case 1:
                scores[40] = 1
                break;
            case 2:
                scores[40] = 4
                break;
            case 3:
                scores[40] = 9
                break;
        }

        scores[41] = answers.q41A ? 5 : 9
        scores[41] = scores[41] + (answers.q41B ? 9 : 5)
        scores[41] = scores[41] + (answers.q41C ? 3 : 8)
        scores[41] = scores[41] + (answers.q41D ? 2 : 8)
        
        switch (answers.q42A) {
            case 0:
                scores[42] = 5
                break;
            case 1:
                scores[42] = 2
                break;
            case 2:
                scores[42] = 8
                break;
        }

        switch (answers.q42B) {
            case 0:
                scores[42] = scores[42] + 2
                break;
            case 1:
                scores[42] = scores[42] + 4
                break;
            case 2:
                scores[42] = scores[42] + 6
                break;
            case 3:
                scores[42] = scores[42] + 9
                break;
        }

        switch (answers.q42C) {
            case 0:
                scores[42] = scores[42] + 9
                break;
            case 1:
                scores[42] = scores[42] + 5
                break;
            case 2:
                scores[42] = scores[42] + 2
                break;
        }
        
        return { 
            x: ((scores[8] / 2) +  scores[10] + scores[11] + ((11 - scores[12]) / 2) + ((11 - scores[13]) / 2) + (scores[16] / 2) + (11 - scores[17]) + (scores[18] / 2) + (11 - scores[22]) + (11 - scores[26]) + scores[27] + (11 - scores[28]) + scores[29] + scores[37] + scores[38] + (11 - scores[39]) + scores[40] + scores[41] + (scores[42] * 3)) / 18.5,
            y: (scores[7] + (11 - scores[8]) + scores[9] + (scores[11] / 2) + (11 - scores[13]) + scores[14] + scores[15] + (11 - scores[16]) + scores[17] + scores[18] + (11 - scores[19]) + scores[20] + (scores[21] / 2) + (11 - scores[23]) + scores[24] + scores[25] + (11 - scores[26]) + (11 - scores[31]) + ((scores[32] + scores[33] + scores[34] + scores[35] + scores[36]) / 2) + (scores[37] * 3) + scores[39] + scores[41] + scores[42]) / 26
        }
    }

    return (
        <div className="result">
            <h3>You have now completed the assessment. Thank you very much.</h3>
            <div id="graph"></div>
        </div>
    )
}
export default function CustomDropdown({question, onChange, options, selectedValue}:{question:string, onChange: (value:string) => void, options:Array<{value:string, text:string}>, selectedValue:string}){
    function handleChange(e:React.ChangeEvent<HTMLSelectElement>){
        onChange(e.target.value)
    }
    
    return (
        <>
            <h3>{ question }</h3>
            <label className="dropdown">
                <select value={selectedValue} onChange={ handleChange }>
                    {
                        options.map((option) => 
                            <option key={ option.value } value={ option.value }>{ option.text }</option>
                        )
                    }
                </select>
            </label>
        </>
    )
}
import { IAnswers } from "../App"
import Slider from "../components/Slider"

export default function Page3({ answers, updateAnswers }:{answers:IAnswers, updateAnswers: React.Dispatch<React.SetStateAction<IAnswers>>}){
    
    return (
        <>
            <span>On a typical day at work, do you consider yourself to be:</span>
            <Slider marginBottom={ 100 } question="12) A person who..." selectedValue={answers.q12} onChange={ (value) => { updateAnswers({ ...answers, q12: value }) }} lowDescription="Needs freedom to use initiative" highDescription="Prefers a clear list of tasks and expectations" />
            <Slider marginBottom={ 120 } question="13) A person who..." selectedValue={answers.q13} onChange={ (value) => { updateAnswers({ ...answers, q13: value }) }} lowDescription="Needs to understand the WHY of a task" highDescription="Needs to understand the WHAT/HOW of a task" />
            <Slider question="14) a person who believes..." selectedValue={answers.q14} onChange={ (value) => { updateAnswers({ ...answers, q14: value }) }} lowDescription="Facts are facts" highDescription="Facts evolve" />
            <Slider marginBottom={ 120 } question="15) a person who believes truth is derived through..." selectedValue={answers.q15} onChange={ (value) => { updateAnswers({ ...answers, q15: value }) }} lowDescription="The scientist's exploration of factual reality" highDescription="The artist's search for meaning" />
            <Slider question="16) a person who likes to operate on the basis of instinct and ‘gut-feel’?" selectedValue={answers.q16} onChange={ (value) => { updateAnswers({ ...answers, q16: value }) }} lowDescription="A lot like me" highDescription="Not like me at all" />
        </>
    )
}